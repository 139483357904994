<template>
  <div>
    <DeleteConfirmationModal
      ref="modal"
      @deleted="() => $refs.languages.fetch()"
      :endpoint="url('/api/1.0/language/' + languageToDelete.id + '/delete')"
      :key="showDelete"
      :message="'Do you want to delete the language ' + languageToDelete.name"/>

    <Card ref="card" :padding="false">
      <template v-slot:header>
        <router-link to="/languages/create">
          <Button label="Create Language" class="-m-2"/>
        </router-link>
      </template>
      <template v-slot:content>
        <HttpGet ref="languages" :url="url('/api/1.0/language?page=' + page + '&filters=' + JSON.stringify(filters))" v-model="$store.state.languages.data">
          <DataTable :store="$store.state.languages.data" :headers="headers" :sorted="sorted" v-slot="data" @page="page => paginate(page)">
            <DataTableColumn>{{ data.row.name }}</DataTableColumn>
            <DataTableColumn>{{ data.row.code.toUpperCase() }}</DataTableColumn>
            <DataTableColumn :key="data.row.id">
              <div class="flex justify-end inline-block">
                <Action class="mr-3" action="edit" icon="edit" label="Edit" @click="() => $router.push('/languages/' + data.row.id + '/edit')"/>
                <Action action="delete" icon="delete" label="Delete" @click="() => openDeleteModal(data.row.id, data.row.name)"/>
              </div>
            </DataTableColumn>
          </DataTable>
        </HttpGet>
      </template>
    </Card>
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator'

import { DataTable, HttpGet, DataTableColumn, Action, Button, Card, Route, Modal, HttpPost, DeleteConfirmationModal } from '../../lib/mavejs'
import { Functions } from '@/mixins/Functions'

@Component({
  components: {
    DeleteConfirmationModal,
    HttpPost,
    DataTableColumn,
    HttpGet,
    DataTable,
    Button,
    Card,
    Route,
    Action,
    Modal
  },
  mixins: [Functions]
})
export default class Languages extends Vue {
  headers = [
    { head: 'Name', key: 'name' },
    { head: 'Code', key: 'code' },
    { head: '', key: '' }
  ];

  sorted = { head: 'Name', key: 'name' }
  showDelete = false;
  page = 1;
  languageToDelete = {}
  filters = {
    search: { column: 'name', value: '' },
    order: { column: 'name', by: 'asc' }
  }

  paginate (currentPage) {
    this.page = currentPage
  }

  openDeleteModal (id, name) {
    this.$refs.modal.open()
    this.languageToDelete = { id: id, name: name }
  }
}
</script>
