import Vue from 'vue'
import { url, def, accs } from '@/functions'

export const Functions = Vue.extend({
  methods: {
    url: url,
    def: def,
    accs: accs
  }
})
